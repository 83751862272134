<template>
  <div class="w-full px-5 py-5">
    <div class="flex justify-end">
      <b-button
        @click="openModalAddOrganizationAssets"
        type="is-small"
        label="+ Organization Asset"
      />
    </div>

    <b-table
      :data="table.data.organizations"
      class="my-4 shadow-2xl"
      striped
      narrowed
    >
      <template v-slot:empty>
        <div class="flex justify-center items-center py-2">
          <span class="text-base" v-if="dowloadingData === false">
            {{ "DATA NOT FOUND" }}
          </span>
        </div>
      </template>
      <b-table-column
        header-class="table-header-bg"
        centered
        field=""
        label="No"
      >
        <template v-slot:header="{ column }">
          <span class="text-xs text-white">{{ column.label }}</span>
        </template>
        <template v-slot="props">
          <span class="text-xs">{{ props.index + 1 }}</span>
        </template>
      </b-table-column>
      <b-table-column
        header-class="table-header-bg"
        centered
        field=""
        label="Organization"
      >
        <template v-slot:header="{ column }">
          <span class="text-xs text-white">{{ column.label }}</span>
        </template>
        <template v-slot="props">
          <span class="text-xs">{{ props.row.organizationName }}</span>
        </template>
      </b-table-column>
      <b-table-column
        header-class="table-header-bg"
        centered
        field=""
        label="Contract Address"
      >
        <template v-slot:header="{ column }">
          <span class="text-xs text-white">{{ column.label }}</span>
        </template>
        <template v-slot="props">
          <span class="text-xs">{{ props.row.contractAddress }}</span>
        </template>
      </b-table-column>
      <b-table-column
        header-class="table-header-bg"
        centered
        field=""
        label="Creator Address"
      >
        <template v-slot:header="{ column }">
          <span class="text-xs text-white">{{ column.label }}</span>
        </template>
        <template v-slot="props">
          <span class="text-xs">{{ props.row.creatorAddress }}</span>
        </template>
      </b-table-column>
      <b-table-column
        header-class="table-header-bg"
        centered
        field=""
        label="Contact Type"
      >
        <template v-slot:header="{ column }">
          <span class="text-xs text-white">{{ column.label }}</span>
        </template>
        <template v-slot="props">
          <span class="text-xs">{{ props.row.contractType }}</span>
        </template>
      </b-table-column>
      <b-table-column
        header-class="table-header-bg"
        centered
        field=""
        label="Actions"
      >
        <template v-slot:header="{ column }">
          <span class="text-xs text-white">{{ column.label }}</span>
        </template>
        <template v-slot="props">
          <div>
            <button @click="handleDeleteOrganizationAssets(props)" class="mr-2">
              <img class="h-5" src="@/assets/img/icon-btn-delete.png" alt="" />
            </button>
            <button @click="handleGetDataOrganizationsById(props)">
              <img class="h-5" src="@/assets/img/icon-btn-edit.png" alt="" />
            </button>
          </div>
        </template>
      </b-table-column>
    </b-table>

    <!-- modal add organization -->
    <b-modal
      :on-cancel="closeModalAddOrganizationAssets"
      :width="450"
      v-model="modal.showModalAddOrganizationAssets"
      size="md"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Add Organization Asset</p>
        </header>
        <section class="modal-card-body">
          <b-field label="Organization">
            <b-select
              v-model="form.add.organizationId"
              expanded
              class="input-modal"
            >
              <option
                v-for="option in options.organization"
                :value="option.value"
                :key="option.value"
              >
                {{ option.text }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Contract Address">
            <b-input
              v-model="form.add.contractAddress"
              class="input-modal"
              type="text"
            >
            </b-input>
          </b-field>
          <b-field label="Creator Address">
            <b-input
              v-model="form.add.creatorAddress"
              class="input-modal"
              type="text"
            >
            </b-input>
          </b-field>
          <b-field label="Contract Type">
            <b-input
              v-model="form.add.contractType"
              class="input-modal"
              type="text"
            >
            </b-input>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <b-button label="Cancle" />
          <b-button
            @click="handleAddOrganizationAssets"
            label="Save"
            type="is-primary"
          />
        </footer>
      </div>
    </b-modal>

    <!-- modal edit organization -->
    <b-modal
      :on-cancel="closeModalEditOrganizationAssets"
      :width="450"
      v-model="modal.showModalEditOrganizationAssets"
      size="md"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Edit Organization Asset</p>
        </header>
        <section class="modal-card-body">
          <b-field label="Organization">
            <b-select
              v-model="form.edit.organizationId"
              expanded
              class="input-modal"
            >
              <option
                v-for="option in options.organization"
                :value="option.value"
                :key="option.value"
              >
                {{ option.text }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Contract Address">
            <b-input
              v-model="form.edit.contractAddress"
              class="input-modal"
              type="text"
            >
            </b-input>
          </b-field>
          <b-field label="Creator Address">
            <b-input
              v-model="form.edit.creatorAddress"
              class="input-modal"
              type="text"
            >
            </b-input>
          </b-field>
          <b-field label="Contract Type">
            <b-input
              v-model="form.edit.contractType"
              class="input-modal"
              type="text"
            >
            </b-input>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <b-button @click="closeModalEditOrganizationAssets" label="Cancle" />
          <b-button
            @click="handleEditOrganizationAssets"
            label="Save"
            type="is-primary"
          />
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "OrganizationAsset",
  data() {
    return {
      _id: "",
      modal: {
        showModalAddOrganizationAssets: false,
        showModalEditOrganizationAssets: false,
      },
      form: {
        add: {
          organizationId: "",
          contractAddress: "",
          creatorAddress: "",
          contractType: "",
        },
        edit: {
          organizationId: "",
          organizationName: "",
          contractAddress: "",
          creatorAddress: "",
          contractType: "",
          id: "",
        },
      },
      table: {
        data: {
          organizations: [],
        },
      },
      options: {
        organization: [],
      },
      dowloadingData: false,
    };
  },
  methods: {
    openModalAddOrganizationAssets() {
      this.modal.showModalAddOrganizationAssets = true;
    },
    closeModalAddOrganizationAssets() {
      this.modal.showModalAddOrganizationAssets = false;
      this.form.add = {
        organizationId: "",
        contractAddress: "",
        creatorAddress: "",
        contractType: "",
      };
    },
    closeModalEditOrganizationAssets() {
      this.modal.showModalEditOrganizationAssets = false;
      this.form.edit = {
        organizationId: "",
        contractAddress: "",
        creatorAddress: "",
        contractType: "",
      };
    },
    handleGetDataOrganizationAssets() {
      this.$axios.get("organizations-assets").then((response) => {
        if (response) {
          this.table.data.organizations = response.data;
        }
      });
    },
    handleAddOrganizationAssets() {
      const data = {
        organizationId: this.form.add.organizationId,
        contractAddress: this.form.add.contractAddress,
        creatorAddress: this.form.add.creatorAddress,
        contractType: this.form.add.contractType,
      };
      this.isLoading = true;
      this.$Swal
        .fire({
          text: "Create Data ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .post("organizations-assets", data)
              .then((response) => {
                if (response) {
                  this.$Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Success Create Data !",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.closeModalAddOrganizationAssets();
                  this.handleGetDataOrganizationAssets();
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
                this.isLoading = false;
              });
          }
        });
    },
    handleGetDataOrganizationsById(props) {
      this.$axios
        .get(`organizations-assets/${props.row._id}`)
        .then((response) => {
          if (response) {
            this.form.edit.id = response.data._id;
            this.form.edit.organizationName = response.data.organizationName;
            this.form.edit.contractAddress = response.data.contractAddress;
            this.form.edit.creatorAddress = response.data.creatorAddress;
            this.form.edit.contractType = response.data.contractType;
            this.modal.showModalEditOrganizationAssets = true;
            this.handleGetOrganizationAssetId();
          }
        });
    },
    handleEditOrganizationAssets() {
      const data = {
        organizationId: this.form.edit.organizationId,
        contractAddress: this.form.edit.contractAddress,
        creatorAddress: this.form.edit.creatorAddress,
        contractType: this.form.edit.contractType,
      };
      this.isLoading = true;
      this.$Swal
        .fire({
          text: "Edit Data ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .put(`organizations-assets/${this.form.edit.id}`, data)
              .then((response) => {
                if (response) {
                  this.$Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Success Edit Data !",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.closeModalEditOrganizationAssets();
                  this.handleGetDataOrganizationAssets();
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
                this.isLoading = false;
              });
          }
        });
    },
    handleDeleteOrganizationAssets(props) {
      this.options.organization.forEach((item) => {
        console.log(item);
        if (item.text === props.row.organizationName) {
          console.log(item.text, item.value);
          this._id = item.value;
        }
      });
      this.$Swal
        .fire({
          text: "Delete Data ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            this.$axios
              .delete(`organizations-assets/${this._id}`)
              .then((response) => {
                if (response) {
                  this.$Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Success Delete Data !",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.handleGetDataOrganizationAssets();
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
                this.isLoading = false;
              });
          }
        });
    },

    handleGetDataOrganizations() {
      this.$axios.get("organizations").then((response) => {
        if (response) {
          this.options.organization = [{ text: "--- Pilih ---", value: "" }];
          response.data.map((item) => {
            const text = item.name;
            const value = item._id;
            this.options.organization.push({ text, value });
          });
        }
      });
    },
    handleGetOrganizationAssetId() {
      this.options.organization.forEach((item) => {
        if (item.text === this.form.edit.organizationName) {
          this.form.edit.organizationId = item.value;
        }
      });
    },
  },
  mounted() {
    this.handleGetDataOrganizationAssets();
    this.handleGetDataOrganizations();
  },
};
</script>
