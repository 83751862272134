<template>
  <div>
    <div v-if="$store.state.token !== null">
      <Navbar />
      <Sidebar />
    </div>
      <router-view />
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Navbar from "@/components/Navbar.vue";
export default {
  components: {
    Navbar,
    Sidebar,
  },
  name: "App",
};
</script>
