<template>
  <b-loading
    :is-full-page="isFullPage"
    v-model="isLoading"
    :can-cancel="true"
  ></b-loading>
  <div class="flex items-center justify-center h-screen hero">
    <div class="form shadow-2xl">
      <div class="flex justify-center" style="height: 550px; margin-top: 80px">
        <div class="flex-col">
          <div class="flex justify-center">
            <img
              class="logo-login mb-3"
              src="@/assets/img/group522.png"
              alt=""
            />
          </div>
          <div class="flex justify-center mb-7 flex-col text-center">
            <span class="text-md font-bold">Welcome Back !</span>
            <span class="text-sm">Please login to continue</span>
          </div>
          <div>
            <span class="text-sm">Email</span>
            <b-input
              icon="email"
              v-model="email"
              placeholder="Please input your email"
              class="w-60 input-modal"
              type="email"
              required
            />
            <div v-if="email === ''">
              <span style="color: red" class="text-xs">Email Wajib Diisi</span>
            </div>
          </div>
          <div class="flex justify-center">
            <b-button @click="login" class="mt-2 w-60 btn-color font-bold"
              >Login</b-button
            >
          </div>
          <div class="flex justify-center">
            <b-button
              icon-left="google"
              class="mt-2 font-bold"
              @click="loginGoogleAuth"
            >
              Login with Google
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      email: "",
      isLoading: false,
    };
  },
  methods: {
    login() {
      if (this.email !== "") {
        this.isLoading = true;
        this.$axios
          .post("auth/simple-login", {
            email: this.email,
          })
          .then((resp) => {
            const data = resp.data;
            let forcookie = JSON.stringify(data);
            this.$cookie.set("token", forcookie, { expires: 1 });

            this.$store.commit("SET_LOGIN", forcookie);
            window.location.reload();

            this.$Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Success Login !",
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$Swal.fire({
          position: "top-center",
          icon: "error",
          title: "Harap isi email terlebih dahulu !",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    loginGoogleAuth() {
      this.isLoading = true;
      this.$axios
        .get("")
        .then((response) => {
          console.log("google", response);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    google() {
      window.location.href = "https://payrollium.b2camp.id/auth/google";
    },
  },
};
</script>
