import { createStore } from 'vuex'

export default createStore({
  state: {
    token: null,
  },
  getters: {
  },
  mutations: {
    SET_LOGIN(state, payload) {
      state.token = payload;
    },
  },
  actions: {
  },
  modules: {
  }
})
