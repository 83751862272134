<template>
  <div class="w-full py-5 px-5">
    <div class="shadow-md container-landing">
      <div
        class="w-full grid grid-cols-1 sm:grid-cols-2 md:lg-grid-cols-2 lg:grid-cols-2 xl:grid-cols-2"
      >
        <div class="flex justify-center items-center">
          <Carousel />
        </div>
        <div class="flex justify-center items-center">
          <span>Welcome To The Adam's</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from "@/components/Carousel.vue";

export default {
  name: "LandingPage",
  components: {
    Carousel,
  },
  data() {
    return {};
  },
};
</script>
