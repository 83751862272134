<template>
  <div class="flex items-center py-5 px-5" style="height: 86vh">
    <b-carousel>
      <b-carousel-item v-for="(carousel, i) in carousels" :key="i">
        <img
          :src="carousel.img"
          class="w-full h-full object-cover"
          style="height: 80vh"
          alt=""
        />
      </b-carousel-item>
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  data() {
    return {
      carousels: [
        { img: require("@/assets/img/org1.jpg") },
        { img: require("@/assets/img/org2.jpg") },
        { img: require("@/assets/img/org4.jpg") },
        { img: require("@/assets/img/org5.jpg") },
        { img: require("@/assets/img/org6.avif") },
      ],
    };
  },
};
</script>
