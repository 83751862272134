import { createRouter, createWebHashHistory } from "vue-router";
import store from "../store";
import LandingPage from "@/views/landing/LandingPage.vue";
import Login from "@/views/login/Login.vue";
import Page404 from "@/views/page404/Page404.vue"
import ListOrganizations from "@/views/organizations/ListOrganizations.vue";
import OrganizationAsset from "@/views/organization-asset/OrganizationAsset.vue";

const routes = [
  {
    path: '/',
    redirect: '/pages/login',
  },
  {
    path: "/:catchAll(.*)",
    component: Page404,
  },
  //
  // {
  //   path: "*",
  //   component: Page404,
  // },
  {
    path: "/pages/login",
    name: "login",
    component: Login,
    meta: {
      guest: true,
    },
  },
  {
    path: "/home",
    name: "landing-page",
    component: LandingPage,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/organizations",
    name: "list-organizations",
    component: ListOrganizations,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/organization-asset",
    name: "organization-asset",
    component: OrganizationAsset,
    meta: {
      requireAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth)) {
    if (store.state.token === null) {
      next("/pages/login");
    }
  }
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.state.token !== null) {
      next("/home");
    }
  }
  next();
});

export default router;
