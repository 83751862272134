<template>
  <div class="container-sidebar shadow-2xl" id="toggle-menu">
    <div style="margin-left: 250px">
      <b-button
        class="text-2xl btn-toggle"
        icon-left="menu"
        @click="toggleMenu"
      />
    </div>
    <div class="flex justify-center" style="margin-top: 100px">
      <img class="p-2 logo-profile" src="@/assets/img/org2.jpg" alt="" />
    </div>
    <div class="flex justify-center">
      <span class="text-xs font-bold">MUHAMAD REVI ADAM PRAYITNO</span>
    </div>
    <div class="flex justify-center my-1.5">
      <span class="text-xs">Superadam@b2camp.id</span>
    </div>
    <div class="flex justify-center mb-4">
      <div
        style="
          background-color: #d12ba1;
          padding: 2px;
          border-radius: 5px;
          color: white;
        "
      >
        <span class="text-xs font-bold">The Adam's</span>
      </div>
    </div>
    <div class="flex justify-center">
      <hr style="border: 0.5px solid grey; width: 230px" />
    </div>
    <div class="flex justify-end mt-4">
      <button @click="pageOrganization" class="btn-menu font-bold">ORGANIZATION</button>
    </div>
    <div class="flex justify-end mt-1">
      <button @click="pageOrganizationAsset" class="btn-menu font-bold">ORGANIZATION ASSET</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
    };
  },
  methods: {
    pageOrganization() {
      this.$router.push("/organizations");
    },
    pageOrganizationAsset() {
      this.$router.push("/organization-asset");
    },
    pageHome() {
      this.$router.push("/home");
    },
    toggleMenu() {
      var element = document.getElementById("toggle-menu");
      var elementRout = document.getElementById("route-view");
      this.isToggle = !this.isToggle;
      if (this.isToggle) {
        element.classList.add("close-sidebar");
      } else {
        element.classList.remove("close-sidebar");
      }
    },
  },
};
</script>

<style></style>
