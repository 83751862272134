<template>
  <div>
    <div
      class="flex justify-center items-center flex-col"
      style="width: 100%; height: 600px"
    >
      <span class="mt-3" style="font-size: 150px; color: black">404</span>
      <span
        class="mt-5"
        style="font-size: 30px; color: black; font-weight: bold"
        >Ooops!!</span
      >
      <span style="font-size: 20px; color: black"
        >HALAMAN TERSEBUT TIDAK ADA ATAU TIDAK TERSEDIA.
      </span>

      <b-button
        @click="handleBackToLandingPage"
        class="mt-10"
        style="
          background-color: #eab308;
          color: white;
          width: 150px;
          height: 50px;
          border-radius: 6px;
        "
      >
        <b>Kembali</b>
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page404",
  methods: {
    handleBackToLandingPage() {
      this.$router.push("/");
    },
  },
};
</script>
