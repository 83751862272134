<template>
  <div class="w-full shadow-md">
    <div style="height: 50px" class="flex justify-end items-center mr-2">
      <b-button
        icon-left="logout"
        class="btn-color"
        @click="deleteCookie('token')"
        size="is-small"
        >Logout</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {};
  },
  methods: {
    deleteCookie(name) {
      document.cookie =
        name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
      window.location.reload();
    },
  },
};
</script>
