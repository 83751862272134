import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "axios";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import "tailwindcss/dist/tailwind.min.css";
import "./assets/scss/style.scss";
import cookie from "js-cookie";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

const axiosInit = axios.create({
  baseURL: "https://payrollium.b2camp.id/",
  timeout: 18000,
  headers: {
    Authorization: "Bearer",
    "Content-Type": "application/json",
  },
});

if (cookie.getJSON("token") !== undefined) {
  const auth = cookie.getJSON("token");
  // const token_access = JSON.parse(auth).token
  store.commit("SET_LOGIN", auth)
  axiosInit.interceptors.request.use(
    (config) => {
      config.headers["Authorization"] = `Bearer ${auth.token}`;
      return config;
    },
    (error) => Promise.reject(error)
  );
}

const app = createApp(App);

app.config.globalProperties.$axios = { ...axiosInit };
app.config.globalProperties.$Swal = Swal;
app.config.globalProperties.$cookie = cookie;

app.use(Buefy);
app.use(store);
app.use(router);
app.mount("#app");
