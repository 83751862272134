<template>
  <b-loading
    :is-full-page="isFullPage"
    v-model="isLoading"
    :can-cancel="true"
  />
  <div class="w-full px-5 py-5">
    <div class="flex justify-end">
      <b-button
        @click="showModalAddOrganizations"
        type="is-small"
        label="+ Organization"
      />
    </div>

    <div>
      <b-table
        :data="table.data.organizations"
        class="my-4 shadow-2xl"
        striped
        narrowed
      >
        <template v-slot:empty>
          <div class="flex justify-center items-center py-2">
            <span class="text-base" v-if="dowloadingData === false">
              {{ "DATA NOT FOUND" }}
            </span>
          </div>
        </template>
        <b-table-column
          header-class="table-header-bg"
          centered
          field=""
          label="No"
        >
          <template v-slot:header="{ column }">
            <span class="text-xs text-white">{{ column.label }}</span>
          </template>
          <template v-slot="props">
            <span class="text-xs">{{ props.index + 1 }}</span>
          </template>
        </b-table-column>
        <b-table-column
          header-class="table-header-bg"
          centered
          field=""
          label="Name"
        >
          <template v-slot:header="{ column }">
            <span class="text-xs text-white">{{ column.label }}</span>
          </template>
          <template v-slot="props">
            <!-- <img class="h-5" :src="props.row.imageUrl" alt="" /> -->
            <span class="text-xs">{{ props.row.name }}</span>
          </template>
        </b-table-column>
        <b-table-column
          header-class="table-header-bg"
          centered
          field=""
          label="Address"
        >
          <template v-slot:header="{ column }">
            <span class="text-xs text-white">{{ column.label }}</span>
          </template>
          <template v-slot="props">
            <span class="text-xs">{{ props.row.address }}</span>
          </template>
        </b-table-column>
        <b-table-column
          header-class="table-header-bg"
          centered
          field=""
          label="Contact"
        >
          <template v-slot:header="{ column }">
            <span class="text-xs text-white">{{ column.label }}</span>
          </template>
          <template v-slot="props">
            <span class="text-xs">{{ props.row.contact }}</span>
          </template>
        </b-table-column>
        <b-table-column
          header-class="table-header-bg"
          centered
          field=""
          label="Actions"
        >
          <template v-slot:header="{ column }">
            <span class="text-xs text-white">{{ column.label }}</span>
          </template>
          <template v-slot="props">
            <div>
              <button @click="handleDeleteOrganization(props)" class="mr-2">
                <img
                  class="h-5"
                  src="@/assets/img/icon-btn-delete.png"
                  alt=""
                />
              </button>
              <button @click="handleGetDataOrganizationsById(props)">
                <img class="h-5" src="@/assets/img/icon-btn-edit.png" alt="" />
              </button>
            </div>
          </template>
        </b-table-column>
      </b-table>
    </div>

    <!-- modal add organization -->
    <b-modal
      :on-cancel="closeModalAddOrganizations"
      :width="350"
      v-model="modal.showModalAddOrganizations"
      size="md"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Add Organization</p>
        </header>
        <section class="modal-card-body">
          <b-field label="Name">
            <b-input v-model="form.add.name" class="input-modal" type="text">
            </b-input>
          </b-field>
          <b-field label="Address">
            <b-input v-model="form.add.address" class="input-modal" type="text">
            </b-input>
          </b-field>
          <b-field label="Url Image">
            <b-input
              v-model="form.add.imageUrl"
              class="input-modal"
              type="text"
            >
            </b-input>
          </b-field>
          <b-field label="Contact">
            <b-input v-model="form.add.contact" class="input-modal" type="text">
            </b-input>
          </b-field>
          <b-field label="Description">
            <b-input
              v-model="form.add.description"
              class="input-modal"
              type="textarea"
            >
            </b-input>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <b-button label="Cancle" />
          <b-button
            @click="handleAddOrganization"
            label="Save"
            type="is-primary"
          />
        </footer>
      </div>
    </b-modal>

    <!-- modal edit organization -->
    <b-modal
      :on-cancel="closeModalEditOrganizations"
      :width="450"
      v-model="modal.showModalEditOrganizations"
      size="md"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Edit Organization</p>
        </header>
        <section class="modal-card-body">
          <b-field label="Name">
            <b-input v-model="form.edit.name" class="input-modal" type="text">
            </b-input>
          </b-field>
          <b-field label="Address">
            <b-input
              v-model="form.edit.address"
              class="input-modal"
              type="text"
            >
            </b-input>
          </b-field>
          <b-field label="Url Image">
            <b-input
              v-model="form.edit.imageUrl"
              class="input-modal"
              type="text"
            >
            </b-input>
          </b-field>
          <b-field label="Contact">
            <b-input
              v-model="form.edit.contact"
              class="input-modal"
              type="text"
            >
            </b-input>
          </b-field>
          <!-- <b-field label="Description">
            <b-input
              v-model="form.edit.description"
              style="width: 400px"
              type="textarea"
            >
            </b-input>
          </b-field> -->
        </section>
        <footer class="modal-card-foot">
          <b-button @click="closeModalEditOrganizations" label="Cancle" />
          <b-button
            @click="handleEditOrganization"
            label="Save"
            type="is-primary"
          />
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ListOrganizations",
  data() {
    return {
      dowloadingData: false,
      isLoading: false,
      file: null,
      modal: {
        showModalAddOrganizations: false,
        showModalEditOrganizations: false,
      },
      form: {
        add: {
          name: "",
          address: "",
          imageUrl: "",
          contact: "",
          description: "",
        },
        edit: {
          name: "",
          address: "",
          imageUrl: "",
          contact: "",
          description: "",
        },
      },
      table: {
        data: {
          organizations: [],
        },
      },
    };
  },
  methods: {
    showModalAddOrganizations() {
      this.modal.showModalAddOrganizations = true;
    },
    closeModalAddOrganizations() {
      this.modal.showModalAddOrganizations = false;
      this.form.add = {
        name: "",
        address: "",
        imageUrl: "",
        contact: "",
        description: "",
      };
    },
    closeModalEditOrganizations() {
      this.modal.showModalEditOrganizations = false;
      this.form.edit = {
        name: "",
        address: "",
        imageUrl: "",
        contact: "",
        description: "",
      };
    },
    handleGetDataOrganizations() {
      this.$axios.get("organizations").then((response) => {
        if (response) {
          this.table.data.organizations = response.data;
        }
      });
    },
    handleAddOrganization() {
      const data = {
        name: this.form.add.name,
        address: this.form.add.address,
        imageUrl: this.form.add.imageUrl,
        contact: this.form.add.contact,
        description: this.form.add.description,
      };
      this.isLoading = true;
      this.$Swal
        .fire({
          text: "Create Data ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .post("organizations", data)
              .then((response) => {
                if (response) {
                  this.$Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Success Create Data !",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.closeModalAddOrganizations();
                  this.handleGetDataOrganizations();
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
                this.isLoading = false;
              });
          }
        });
    },
    handleEditOrganization() {
      const data = {
        name: this.form.edit.name,
        address: this.form.edit.address,
        imageUrl: this.form.edit.imageUrl,
        contact: this.form.edit.contact,
        description: this.form.edit.description,
      };
      this.isLoading = true;
      this.$Swal
        .fire({
          text: "Edit Data ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .put(`organizations/${this.form.edit.id}`, data)
              .then((response) => {
                if (response) {
                  this.$Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Success Edit Data !",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.closeModalEditOrganizations();
                  this.handleGetDataOrganizations();
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
                this.isLoading = false;
              });
          }
        });
    },
    handleGetDataOrganizationsById(props) {
      this.$axios.get(`organizations/${props.row._id}`).then((response) => {
        if (response) {
          this.form.edit.id = response.data._id;
          this.form.edit.name = response.data.name;
          this.form.edit.address = response.data.address;
          this.form.edit.imageUrl = response.data.imageUrl;
          this.form.edit.contact = response.data.contact;
          this.modal.showModalEditOrganizations = true;
        }
      });
    },
    handleDeleteOrganization(props) {
      this.$Swal
        .fire({
          text: "Delete Data ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            this.$axios
              .delete(`organizations/${props.row._id}`)
              .then((response) => {
                if (response) {
                  this.$Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Success Delete Data !",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.handleGetDataOrganizations();
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
                this.isLoading = false;
              });
          }
        });
    },
  },
  mounted() {
    this.handleGetDataOrganizations();
  },
};
</script>
